import { useEffect, useState } from 'react'
import { HiArrowDown, HiArrowUp } from 'react-icons/hi'
import CurrencyLogo from '../../../components/CurrencyLogo/CurrencyLogo'
import { useApiContext } from '../../../utilis/contexts/ApiContext'
import { randomKey } from '../../../utilis/functions'
export default function Market() {
   const [data, setData] = useState()
   const { currencies } = useApiContext()

   useEffect(() => {
      setData(currencies)
      // !isLoadingCurrencies &&
      //    !isLoadingAssets &&
      //    setData(mergeData(currencies, assets))
   }, [currencies])
   return (
      <div className="flex-1 rounded-lg dark:border-secondary md:bg-darktext md:p-4 dark:md:bg-dark-400">
         {/* Choisir une */}
         <div className="justify- flex max-h-screen w-full flex-col items-center gap-4 overflow-y-auto">
            {data ? (
               data?.map((currency) => (
                  <Currency
                     currency={currency}
                     key={currency?.id + randomKey()}
                  />
               ))
            ) : (
               <div className="flex w-full flex-col items-center justify-center gap-4 rounded-lg bg-darktext-white p-4 dark:bg-secondary">
                  <span>Donnés du marché Indisponible</span>
               </div>
            )}
         </div>
      </div>
   )
}

function Currency({ currency }) {
   return (
      <div className="flex w-full flex-row items-center justify-between rounded-lg bg-darktext-white p-4 dark:bg-secondary">
         <div className="flex flex-row items-center gap-2">
            <CurrencyLogo img={currency?.cover} />
            <div className="jutify-center flex flex-col">
               <span className="font-bold text-secondary dark:text-darktext-white sm:text-5">
                  {currency?.name}
               </span>
               <span className="text-3 text-darktext">
                  {currency?.short_name}
               </span>
            </div>
         </div>
         <div className="flex flex-row items-center gap-2">
            <span className="font-semibold">
               {/* $ {parseFloat(currency?.price)}  */}${' '}
               {currency?.price
                  ? parseFloat(currency?.price).toFixed(2)
                  : '0.00'}
            </span>
            <div className="flex items-center gap-1">
               {/* <span className="text-3 text-darktext">0.23%</span> */}
               <HiArrowDown className="text-4 text-red-500" />
               <HiArrowUp className="text-4 text-green-500" />
            </div>
         </div>
      </div>
   )
}
