import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './styles/index.css'
// import InternetState from './components/InternetState'
import { useThemeMode } from 'flowbite-react'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrandCrashed } from './components/Brand'
import InternetState from './components/InternetState'
import PopUp from './components/PopUp'
// import Admin from './pages/admin'
import Auth from './pages/auth/login'
import Client from './pages/client'
import { ActionProvider } from './utilis/contexts/ActionContext'
import { ApiProvider } from './utilis/contexts/ApiContext'
import { NavProvider } from './utilis/contexts/NavContext'
import { PopUpProvider } from './utilis/contexts/PopUpContext'
import { PurchaseProvider } from './utilis/contexts/PurchaseContext'
import { SellingProvider } from './utilis/contexts/SellingContext'
import { SessionProvider } from './utilis/contexts/SessionContext'
import { SwapProvider } from './utilis/contexts/SwapContext'
import { TransactionsActionProvider } from './utilis/contexts/TransactionsActionContext'
import { disableZoom } from './utilis/functions'

export default function App() {
   const { mode } = useThemeMode()

   useEffect(() => {
      handleThem(mode)
   }, [mode])

   const handleThem = (mode) => {
      const metaThemeColor = document.querySelector('meta[name="theme-color"]')
      metaThemeColor.content = mode === 'dark' ? '#1C2129' : '#EAEAEA'
   }
   useEffect(() => {
      window.onbeforeunload = confirmExit
      function confirmExit() {
         return 'Vous êtes sur le point de quitter Mude Capital, le voulez-vous vraiment?'
      }
   }, [])
   disableZoom()
   return (
      <ErrorBoundary fallback={<BrandCrashed crashed={true} />}>
         <SessionProvider>
            <ApiProvider>
               <NavProvider>
                  <PopUpProvider>
                     <ActionProvider>
                        <PurchaseProvider>
                           <SellingProvider>
                              <SwapProvider>
                                 <TransactionsActionProvider>
                                    <Router>
                                       <Routes>
                                          <Route
                                             path="/auth"
                                             element={<Auth />}
                                          />
                                          <Route
                                             path="/*"
                                             element={<Client />}
                                          />
                                          {/* <Route
                                             path="/*"
                                             element={<Admin />}
                                          />
                                          <Route
                                             path="/admin/*"
                                             element={<Admin />}
                                          /> */}
                                       </Routes>
                                    </Router>
                                    <PopUp />
                                    <InternetState />
                                 </TransactionsActionProvider>
                              </SwapProvider>
                           </SellingProvider>
                        </PurchaseProvider>
                     </ActionProvider>
                  </PopUpProvider>
               </NavProvider>
            </ApiProvider>
         </SessionProvider>
      </ErrorBoundary>
   )
}
