import { Button } from 'flowbite-react'
import { FaEquals } from 'react-icons/fa6'
import { useActionContext } from '../../../../../utilis/contexts/ActionContext'
import { useSellingContext } from '../../../../../utilis/contexts/SellingContext'
import {
   InputAmout,
   InputWallet,
   SelectCurrency,
   SelectPaymentMethod,
   ShowAmount,
} from '../_componenets'
import SellingModal from './SellingModal'
export default function Purchase() {
   const { selling, addWallet, clear, addMethod, openModal } =
      useSellingContext()
   return (
      <div className="flex-1 rounded-lg dark:border-secondary md:bg-darktext md:p-4 dark:md:bg-dark-400">
         {/* Choisir une */}
         <div className="flex w-full flex-col items-center gap-4">
            <Currency />
            {selling?.amount && (
               <>
                  <div className="flex w-full flex-row justify-between gap-4">
                     <ShowAmount
                        name="Frais"
                        amount={{
                           value: selling.fees,

                           currency: '$',
                        }}
                     />
                     <ShowAmount
                        name="Total"
                        amount={{
                           value: selling?.total,
                           currency: '$',
                        }}
                     />
                  </div>
                  <SelectPaymentMethod sendValue={addMethod} />
                  {selling.method && (
                     <>
                        <InputWallet
                           sendValue={addWallet}
                           value={selling?.wallet}
                           placeholder={
                              'Ajouter le Numero ' + selling.method.name
                           }
                        />
                        {selling.wallet && (
                           <div className="flex w-full flex-row gap-4">
                              <Button
                                 onClick={() => openModal()}
                                 className="w-full rounded-2xl bg-primary text-6 font-semibold text-white"
                              >
                                 Vendre
                              </Button>
                              <Button
                                 onClick={() => clear()}
                                 color="gray"
                                 className=" w-full rounded-2xl text-6 font-semibold"
                              >
                                 Annuler
                              </Button>
                           </div>
                        )}
                     </>
                  )}
               </>
            )}
         </div>
         <SellingModal />
      </div>
   )
}

function Currency() {
   const { activeAction } = useActionContext()
   const { selling, addAmount, addCurrency } = useSellingContext()

   return (
      <div className="flex w-full flex-col items-center justify-between rounded-lg bg-darktext-white  p-4 dark:bg-secondary md:bg-darktext-white">
         <div className="flex w-full items-center justify-start px-2 ">
            <span className="md:text-5 md-max:text-secondary dark:md-max:text-darktext-white">
               Montant à {activeAction}
            </span>
         </div>
         <div className="flex w-full flex-row items-center justify-between gap-2 px-2">
            <InputAmout sendValue={addAmount} amount={selling?.amount} />
            <FaEquals className="text-7" />
            <SelectCurrency
               sendValue={addCurrency}
               amount={selling?.amount}
               value={selling?.currency}
            />
         </div>
      </div>
   )
}
