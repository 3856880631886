import { Modal, ModalBody } from 'flowbite-react'
import { FaX } from 'react-icons/fa6'
import { TransactionStateTag } from '.'
import CurrencyLogo, {
   MethodLogo,
} from '../../../../../../components/CurrencyLogo/CurrencyLogo'
import { usePurchaseContext } from '../../../../../../utilis/contexts/PurchaseContext'

export default function PurchaseModal() {
   const { closeShowModal, showPurchaseModalState, show } = usePurchaseContext()
   return (
      show?.amount && (
         <Modal
            size="3xl"
            show={showPurchaseModalState}
            onClose={closeShowModal}
            popup
            dismissible={true}
            // className="mb-4 pb-4"
         >
            <ModalBody className="rounded-lg p-2 pt-4 dark:bg-secondary sm:p-4 md:p-5">
               <div className="flex max-h-[100dvh] w-full flex-col justify-between gap-4 md-max:mb-14">
                  <div className="flex flex-row items-center justify-between">
                     <span className="p-4"></span>
                     <span className="text-5 font-semibold">Achat de</span>
                     <FaX
                        onClick={() => closeShowModal()}
                        className="cursor-pointer rounded-full bg-darktext-white p-2 text-8 font-bold text-secondary hover:bg-red-700 dark:bg-dark dark:text-darktext-white"
                     />
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-center gap-2 text-5 font-bold md:text-7 ">
                        <span className="text-overflow ">
                           {Number(show.amount) / Number(show.price)}
                        </span>
                        <CurrencyLogo img={show?.currency?.cover} />
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Prix en USD :</span>
                        <span>{show?.amount} $</span>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Frais :</span>
                        <span>{show?.rate} $</span>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Total payé :</span>
                        <span>
                           {(
                              parseFloat(show?.amount) + parseFloat(show?.rate)
                           ).toFixed(2)}{' '}
                           $
                        </span>
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Moyen de Paiement :</span>
                        <div className="flex items-center gap-1 ">
                           <MethodLogo img={show?.payment_method?.cover} />
                           {show?.method?.name}
                        </div>
                     </div>
                     <div className="flex w-full flex-row   items-center justify-between ">
                        <span className="">Votre Portefeuille :</span>
                        <span className="">{show?.payment_method?.wallet}</span>
                     </div>
                     <div className="flex w-full flex-row   items-center justify-between ">
                        <span className="">Reference :</span>
                        <span className="">{show?.reference}</span>
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row   items-center justify-between ">
                        <span className="">Statuts :</span>
                        <TransactionStateTag state={show?.state} />
                     </div>
                  </div>
               </div>
            </ModalBody>
         </Modal>
      )
   )
}
