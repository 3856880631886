import { HiChevronLeft } from 'react-icons/hi'

export default function CustomPopUp({ children, state, close, title }) {
   return (
      state === true && (
         <div className="fixed left-0 top-0 z-100 flex h-[100dvh] w-screen items-center justify-center bg-black bg-opacity-40 p-4">
            <div className="flex max-h-[90dvh] w-full flex-col gap-2 overflow-y-auto rounded-xl  bg-bg pb-4 dark:bg-dark-400 sm:w-120 md:w-160">
               <div className="flex h-10 w-full items-center justify-between rounded-t-xl border-b p-4 dark:border-none dark:bg-dark ">
                  <HiChevronLeft
                     onClick={() => close()}
                     className="material-icons-round cursor-pointer justify-end rounded text-8 text-dark hover:bg-darktext dark:text-darktext dark:hover:bg-dark-400"
                  />
                  <span className="font-semibold text-dark dark:text-darktext-white">
                     {title}
                  </span>
                  <span></span>
               </div>
               {children}
            </div>
         </div>
      )
   )
}
