import { CgArrowsExchangeAlt } from 'react-icons/cg'
import CurrencyLogo from '../../../../../../components/CurrencyLogo/CurrencyLogo'
import { useApiContext } from '../../../../../../utilis/contexts/ApiContext'
import { useSwapContext } from '../../../../../../utilis/contexts/SwapContext'
import { TransactionStateTag } from '../Purchase'
import SwapModal from './SwapModal'
export default function Swap() {
   const { user } = useApiContext()
   return (
      <div className="h-200 flex-1 overflow-y-auto rounded-lg dark:bg-dark-400 md:bg-darktext md:p-4 dark:md:border-secondary">
         {/* Choisir une */}
         <div className="flex w-full flex-col items-center gap-4">
            {user?.swaps?.length > 0 ? (
               user.swaps.map((swap) => <Currency purchase={swap} />)
            ) : (
               <div className="w-full p-4 text-center text-dark dark:text-darktext-white">
                  <span>
                     Vous n'avez effectuez aucun Echange pour le moment.
                  </span>
               </div>
            )}
         </div>
         <SwapModal />
      </div>
   )
}

function Currency({ purchase }) {
   const { openShowModal } = useSwapContext()
   return (
      <div
         onClick={() => openShowModal(purchase)}
         className=" justify- flex w-full cursor-pointer flex-row items-center gap-2 rounded-lg bg-darktext-white px-4 py-4  dark:bg-secondary md:bg-darktext-white md:p-4"
      >
         <div className="flex items-center gap-2">
            <div className="flex items-center justify-center gap-2 rounded-lg p-2 dark:bg-dark-400">
               {purchase?.amount && (
                  <span className="font-bold md:text-6">
                     {purchase?.amount}
                  </span>
               )}
               {purchase && (
                  <CurrencyLogo
                     className="h-6 w-6"
                     img={purchase?.from?.cover}
                  />
               )}
            </div>
            <CgArrowsExchangeAlt className=" text-6 md:text-10" />
            <div className="flex items-center justify-center gap-2 rounded-lg p-2 dark:bg-dark-400">
               {purchase?.amount && (
                  <span className="font-bold md:text-6">
                     {(
                        parseFloat(purchase?.amount) *
                           parseFloat(purchase?.price) -
                        parseFloat(purchase?.rate)
                     ).toFixed(2)}{' '}
                  </span>
               )}
               {purchase && (
                  <CurrencyLogo className="h-6 w-6" img={purchase?.to?.cover} />
               )}
            </div>
         </div>
         <div className="flex w-full flex-row items-center justify-between">
            <div className="flex flex-col gap-2">
               {purchase?.currency && (
                  <span className="text-6 font-bold">
                     {purchase?.currency.name}
                  </span>
               )}
               {/* {purchase?.amount && (
                  <span className="text- font-bold">{purchase?.amount} $</span>
               )} */}
            </div>
            <div className="gap- hidden flex-col items-end  sm:flex">
               <TransactionStateTag state={purchase?.state} />
            </div>
         </div>
      </div>
   )
}
