import { useSwapContext } from '../../../../../utilis/contexts/SwapContext'
import {
   InputAmout,
   InputWallet,
   SelectCurrency,
   ShowAmount,
} from '../_componenets'

import { Button } from 'flowbite-react'
import { CgArrowsExchangeAltV } from 'react-icons/cg'
import SwapModal from './SwapModal'
export default function Swap() {
   const { swap, openModal, clear, addWallet } = useSwapContext()
   return (
      <div className="flex-1 rounded-lg dark:border-secondary md:bg-darktext md:p-4 dark:md:bg-dark-400">
         {/* Choisir une */}
         <div className="flex w-full flex-col items-center gap-4">
            <Currency />
            {swap?.amount && (
               <>
                  <div className="flex w-full flex-row justify-between gap-4">
                     <ShowAmount
                        name="Frais"
                        amount={{
                           value: swap.fees,

                           currency: ' ',
                        }}
                     />
                     {/* <ShowAmount
                        name="Total"
                        amount={{
                           value: swap?.total,
                           currency: '$',
                        }}
                     /> */}
                  </div>
                  {/* <SelectPaymentMethod sendValue={addMethod} /> */}
                  {swap?.total && (
                     <>
                        <InputWallet
                           sendValue={addWallet}
                           value={swap?.wallet}
                           placeholder={'Votre Portefeuille ' + swap?.to?.name}
                        />
                        {swap?.wallet && (
                           <div className="flex w-full flex-row gap-4">
                              <Button
                                 onClick={() => openModal()}
                                 className="w-full rounded-2xl bg-primary text-6 font-semibold text-white"
                              >
                                 Echanger
                              </Button>
                              <Button
                                 onClick={() => clear()}
                                 color="gray"
                                 className=" w-full rounded-2xl text-6 font-semibold"
                              >
                                 Annuler
                              </Button>
                           </div>
                        )}
                     </>
                  )}
               </>
            )}
         </div>
         <SwapModal />
      </div>
   )
}

function Currency() {
   // const { activeAction } = useActionContext()
   const { swap, addAmount, swapFrom, swapTo, flip } = useSwapContext()

   return (
      <div className="gap- flex w-full flex-col items-center">
         <div className="rounded-t-l-4 flex w-full flex-col items-center justify-between rounded-t-4 bg-darktext-white px-4 pt-4 dark:bg-secondary md:bg-darktext-white">
            <div className="flex w-full items-center justify-start px-2 ">
               <span className=" md:text-5 md-max:text-secondary dark:md-max:text-darktext-white">
                  Envoyer
               </span>
            </div>
            <div className="flex w-full flex-row items-center justify-between gap-2 px-2">
               <InputAmout
                  min={0.000014}
                  sendValue={addAmount}
                  amount={swap?.amount}
                  isSwap={true}
               />
               {/* <FaEquals className="text-7" /> */}
               <SelectCurrency sendValue={swapFrom} value={swap?.from} />
            </div>
         </div>
         <div className="flex w-full flex-row items-center justify-center gap-2  bg-darktext-white dark:bg-secondary md:bg-darktext-white">
            <hr className="w-5/12 border border-dark-400" />
            <CgArrowsExchangeAltV
               onClick={() => flip()}
               className="cursor-pointer rounded-full bg-darktext p-2 text-10 hover:bg-tertiery dark:bg-dark-400 dark:hover:bg-tertiery"
            />
            <hr className="w-5/12 border border-dark-400" />
         </div>
         <div className="rounded-t-l-4 flex w-full flex-col items-center  justify-between rounded-b-4 bg-darktext-white px-4 pb-4 dark:bg-secondary md:bg-darktext-white">
            <div className="flex w-full items-center justify-start px-2 ">
               <span className=" md:text-5 md-max:text-secondary dark:md-max:text-darktext-white">
                  Recevez
               </span>
            </div>
            <div className="flex w-full flex-row items-center justify-between gap-2 px-2">
               <InputAmout
                  // sendValue={addAmount}
                  amount={swap?.total}
                  isSwap={true}
                  readOnly={true}
               />
               {/* <FaEquals className="text-7" /> */}
               {swap.from && (
                  <SelectCurrency
                     sendValue={swapTo}
                     index={1}
                     value={swap?.to}
                  />
               )}
            </div>
         </div>
      </div>
   )
}
