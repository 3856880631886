import { useEffect, useState } from 'react'
import Login from '../../pages/auth/login'
import { useApiContext } from '../../utilis/contexts/ApiContext'
import { useSessionContext } from '../../utilis/contexts/SessionContext'
import { useScreenWidth } from '../../utilis/hooks/display'

import Brand from '../../components/Brand'
import MobileNavBar from '../../components/MobileNavBar'
import NavBar from '../../components/NavBar'
import SideBar from '../../components/SideBar'

export default function Layout({ children }) {
   const { loggedUser } = useSessionContext()
   const {
      isLoading,
      isLoadingPaymentMethods,
      isLoadingCurrencies,
      error,
      user,
      // refresh
   } = useApiContext()
   const [serverError, setServerError] = useState(false)
   const [brand, setBrand] = useState(true)
   const [isLoggedIn, setIsLoggedIn] = useState(false)
   const [sideBar, setSideBar] = useState(true)
   const ToggleSideBar = () => {
      setSideBar(!sideBar)
   }
   const [isSmallScreen] = useScreenWidth()

   useEffect(() => {
      // console.log(error?.response.status)
      if (!isLoading && !isLoadingPaymentMethods && !isLoadingCurrencies) {
         // console.log(error)
         if (
            (error && error?.response?.status === 500) ||
            error?.code === 'ERR_NETWORK'
         ) {
            setServerError(true)
         } else {
            setBrand(false)
            setServerError(false)
         }
         if ((error && error?.response?.status === 403) || !loggedUser) {
            setIsLoggedIn(false)
         } else if (user && loggedUser) {
            // loggedUser.is_admin === 1 &&
            setIsLoggedIn(true)
         }
      }
   }, [
      loggedUser,
      isLoading,
      isLoadingPaymentMethods,
      isLoadingCurrencies,
      error,
      user,
   ])

   useEffect(() => {
      console.log(isSmallScreen)
      isSmallScreen && setSideBar(false)
      !isSmallScreen && setSideBar(true)
   }, [isSmallScreen])

   return brand ? (
      <Brand error={serverError} setError={setServerError} />
   ) : loggedUser || isLoggedIn ? (
      <div className="urbanist max-w-screen flex w-screen flex-row items-center md:p-0">
         <div className="max-w-screen flex h-full w-full flex-col-reverse items-center md:flex-row  md:rounded-2xl">
            {sideBar && <SideBar />}
            {/* mobile navbar */}
            <MobileNavBar />
            <div className="h-100dvh flex w-full flex-col gap-2 p-2 pb-8 md:gap-4 md:p-4 md-max:pb-24">
               <NavBar ToggleSideBar={ToggleSideBar} sideBar={sideBar} />
               <div className="w-full flex-1  overflow-y-auto rounded-lg p-2 dark:border-secondary md:border md:p-4">
                  {children}
               </div>
            </div>
         </div>
      </div>
   ) : (
      <Login />
   )
}
