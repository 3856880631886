import { useEffect } from 'react'
import { FaChartLine } from 'react-icons/fa6'
import ContentLoader from '../../../components/ContentLoader'
import { useApiContext } from '../../../utilis/contexts/ApiContext'
import { useNavContext } from '../../../utilis/contexts/NavContext'
import { useScreenWidth } from '../../../utilis/hooks/display'
import { Wallet } from '../profile'
import { ActionButton } from '../_components/ActionBar'
import Market from '../_components/Market'
import ActionBar from './_components/ActionBar'
import Actions from './_components/Actions'

export default function Transactions() {
   const { handler } = useNavContext()
   const { refresh, isLoading, user } = useApiContext()
   const [id, isMobile] = useScreenWidth()

   useEffect(() => {
      handler('Transactions')
      refresh()
      id && console.log()
   }, [])
   return (
      <div className="md:justify- flex h-full flex-col gap-4 pb-2 md:flex-row">
         <div className="flex w-full flex-col gap-4 md:h-full lg:w-7/12">
            {isMobile && <Wallet />}
            <ActionBar />
            {isLoading && !user ? <ContentLoader /> : <Actions />}
         </div>
         <div className=" flex w-full flex-col gap-4 md:h-full md-max:hidden lg:w-5/12">
            <div className="flex justify-start">
               <ActionButton disabled name={'Marché'} Icon={FaChartLine} />
            </div>
            <Market />
         </div>
      </div>
   )
}
