import { Route, Routes } from 'react-router-dom'
import Home from './home'
import Layout from './layout'
import Parrainange from './parrainage'
import Profile from './profile'
import Transactions from './transactions'

export default function Client() {
   return (
      <Layout>
         {/* <Router> */}
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/parrainages" element={<Parrainange />} />
            <Route path="/account" element={<Profile />} />
            <Route path="*" element={<Home />} />
         </Routes>
         {/* </Router> */}
      </Layout>
   )
}
