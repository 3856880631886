import { Dropdown, Toast, Tooltip } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { FaCopy, FaMoneyBill, FaPaste, FaWallet, FaX } from 'react-icons/fa6'
import { currencies, payment_methods } from '../../../../../../utilis/data'
import {
   copyTextToClipboard,
   pasteTextFromClipboard,
   randomKey,
} from '../../../../../../utilis/functions'

export function InputAmout({
   sendValue,
   amount,
   min = 10,
   max = 500,
   isSwap,
   readOnly = false,
}) {
   const [val, setVal] = useState()
   const [error, setError] = useState(false)
   const handleChange = (e) => {
      const inputVal = e.target.value
      setVal(inputVal)
   }

   useEffect(() => {
      amount && setVal(amount)
   }, [amount])

   useEffect(() => {
      if (val >= min && val <= max) {
         sendValue && sendValue(val)
         setError(false)
      } else {
         sendValue && sendValue()
         setError(true)
      }
   }, [val])
   return (
      <div className="flex w-5/12 flex-col items-center gap-1">
         <div className="flex w-full flex-row items-center justify-center gap-2">
            {!isSwap && (
               <span className="text-5 font-bold sm:text-7 md-max:text-secondary dark:md-max:text-darktext-white">
                  $
               </span>
            )}
            <input
               style={{
                  outline: 'none',
                  boxShadow: 'none',
               }}
               onChange={handleChange}
               className={
                  (error ? ' text-pink-900 dark:text-pink-600' : '') +
                  ' w-full border-b border-none bg-darktext-white bg-none p-0  text-7 font-bold outline-none focus:outline-none active:outline-none dark:bg-secondary sm:text-7 md:bg-darktext-white'
               }
               type="number"
               placeholder="10.00"
               // step="0.01"
               value={val ? val : ''}
               min="11.00"
               max="500.00"
               readOnly={readOnly}
            />
         </div>
         {error ? (
            <hr className="w-full  border-2 border-pink-800 dark:border-pink-600" />
         ) : (
            <hr className="w-full  border-2 border-secondary dark:border-darktext" />
         )}
      </div>
   )
}

export function ShowAmount({
   name = 'Montant',
   amount = { value: 10, currency: '$' },
}) {
   return (
      <div className="flex w-full flex-col items-center justify-between rounded-lg bg-darktext-white p-3 dark:bg-secondary sm:p-4 md:bg-darktext-white md-max:text-secondary dark:md-max:text-darktext">
         <div className="justofy-start flex w-full items-center justify-between">
            <span className="font-bold text-secondary dark:text-darktext sm:text-5">
               {name}
            </span>
            <span className="font-bold dark:text-darktext-white sm:text-5 md-max:text-dark">
               {amount.currency} {amount.value}
            </span>
         </div>
      </div>
   )
}

export function InputWallet({ sendValue, value, placeholder, copy }) {
   const [val, setVal] = useState()
   const [toast, setToast] = useState(false)
   // const [error, setError] = useState(false)
   const handleChange = (e) => {
      const inputVal = e.target.value
      setVal(inputVal)
   }
   const handleTost = (message) => {
      setToast(message)

      setTimeout(() => setToast(), 2000)
   }
   useEffect(() => {
      sendValue && sendValue(val)
   }, [val])
   useEffect(() => {
      value && setVal(value)
   }, [])
   return (
      <div className="flex w-full flex-col items-center justify-between rounded-lg bg-darktext-white p-4 dark:bg-secondary md:bg-darktext-white">
         <div className="flex w-full flex-row items-center justify-center gap-2">
            <FaWallet className="text-6 " />
            <input
               style={{
                  outline: 'none',
                  boxShadow: 'none',
               }}
               readOnly={copy ? true : false}
               onChange={handleChange}
               className="w-full border-b border-none bg-darktext-white bg-none p-0  text-5 font-bold outline-none focus:outline-none active:outline-none dark:bg-secondary md:bg-darktext-white"
               type="text"
               placeholder={placeholder ? placeholder : 'Votre Wallet Address'}
               value={val}
            />
            {!copy && !value && (
               <Tooltip content="Coller" color="auto" placement="top">
                  <FaPaste
                     onClick={() => {
                        pasteTextFromClipboard().then((wallet) => {
                           setVal(wallet)
                           handleTost("L'adresse a été collée")
                        })
                     }}
                     className="cursor-pointer text-6 text-secondary hover:text-dark dark:text-darktext dark:hover:text-darktext-white"
                  />
               </Tooltip>
            )}
            {!copy && value && (
               <Tooltip content="Effacer" color="auto" placement="top">
                  <FaX className="p-1 text-6" onClick={() => setVal('')} />
               </Tooltip>
            )}
            {copy && (
               <Tooltip content="Copier" color="auto" placement="top">
                  <FaCopy
                     className="cursor-pointer text-6"
                     onClick={() => {
                        copyTextToClipboard(val).then((val) => {
                           handleTost("L'adresse a été copiée")
                        })
                     }}
                  />
               </Tooltip>
            )}
            {toast && <TheToast toast={toast} />}
         </div>
      </div>
   )
}

export function SelectCurrency({ sendValue, amount }) {
   const [selectedCurrency, setSelectedCurrency] = useState(currencies[0])
   const [val, setVal] = useState()
   const handleChange = (currency) => {
      sendValue && sendValue(currency)
      setSelectedCurrency(currency)
   }
   useEffect(() => {
      amount && setVal(amount)
   }, [amount])

   useEffect(() => {
      sendValue(currencies[0])
   }, [])
   return (
      <div className="flex flex-1 flex-row items-center justify-center gap-2 text-6 font-semibold md-max:text-secondary dark:md-max:text-darktext-white">
         {amount && (
            <input
               style={{
                  outline: 'none',
                  boxShadow: 'none',
               }}
               className="w-8/12 border-b border-none bg-darktext-white bg-none p-0  text-right text-7 font-bold outline-none focus:outline-none active:outline-none dark:bg-secondary md:bg-darktext-white"
               disabled
               placeholder="0.00"
               value={val ? Number(val) * selectedCurrency.price : ''}
            />
         )}

         <Dropdown
            className="text-4 sm:text-6"
            label={<selectedCurrency.avatar />}
            arrowIcon={!amount}
            inline
         >
            {currencies.map((currency) => (
               <Dropdown.Item
                  className="flex flex-row items-center gap-1 font-semibold"
                  onClick={() => handleChange(currency)}
                  key={currency.name + randomKey()}
               >
                  <currency.avatar />
                  {currency.name}
               </Dropdown.Item>
            ))}
         </Dropdown>
      </div>
   )
}

export function SelectPaymentMethod({ sendValue }) {
   const [selectedCurrency, setSelectedCurrency] = useState(payment_methods[0])
   const handleChange = (method) => {
      sendValue && sendValue(method)
      setSelectedCurrency(method)
   }
   useEffect(() => {
      sendValue && sendValue(payment_methods[0])
   }, [])
   return (
      <div className="flex w-full flex-col items-center justify-between rounded-lg bg-darktext-white  p-4 dark:bg-secondary md:bg-darktext-white">
         <div className="flex w-full flex-row items-center justify-between gap-2">
            {/* <span className="text-5 font-semibold dark:text-darktext">
               Moyen de Payment
            </span> */}
            <div className="flex flex-row items-center gap-2">
               <Tooltip
                  content="Moyen de Paiement"
                  color="auto"
                  placement="right"
               >
                  <FaMoneyBill className="text-6 text-secondary dark:text-darktext" />
               </Tooltip>
               {/* <span className="fomt-semibold text-5">{selectedCurrency}</span> */}
            </div>
            <div className="flex flex-row items-center justify-between gap-2 text-5 font-semibold md:text-6">
               <Dropdown
                  className=" text-4 sm:text-6"
                  label={selectedCurrency.name}
                  inline
               >
                  {payment_methods &&
                     payment_methods.map((method) => (
                        <Dropdown.Item
                           className="text-5 font-semibold"
                           onClick={() => handleChange(method)}
                           key={method?.id + method?.wallet + randomKey()}
                        >
                           {method.name}
                        </Dropdown.Item>
                     ))}
               </Dropdown>
            </div>
         </div>
      </div>
   )
}

// function ActionLocalButton({ name, Icon, className, disabled }) {
//    const { openAction, activeAction } = useActionContext()
//    return (
//       <Tooltip
//          content={name}
//          hidden={activeAction === name}
//          className={activeAction === name ? 'hidden' : 'md:hidden'}
//          color="auto"
//          placement="bottom"
//       >
//          <div
//             onClick={() => !disabled && openAction(name)}
//             className={
//                className +
//                ' ' +
//                (activeAction === name
//                   ? 'flex cursor-pointer flex-row items-center justify-center gap-2 rounded-full bg-darktext-white bg-secondary px-4 py-2  text-5 font-semibold text-bg dark:bg-darktext dark:text-secondary lg:text-5'
//                   : 'flex cursor-pointer flex-row items-center justify-center gap-2 rounded-full bg-darktext-white p-2 text-5 font-semibold hover:bg-tertiery  hover:text-darktext-white dark:bg-secondary dark:text-darktext dark:hover:bg-darktext dark:hover:text-secondary sm:px-4 sm:py-2 lg:text-5')
//             }
//          >
//             {Icon ? <Icon className="text-6" /> : <HiCash className="text-6" />}
//             <span className={activeAction === name ? '' : 'sm-max:hidden'}>
//                {name}
//             </span>
//          </div>
//       </Tooltip>
//    )
// }

function TheToast({ toast }) {
   return (
      <div className="absolute z-10 mb-32 md-max:right-8">
         <Toast className="">
            <div className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-cyan-200">
               <FaCopy className="h-5 w-5 p-1" />
            </div>
            <div className="ml-3 text-sm font-normal">{toast}</div>
            <Toast.Toggle />
         </Toast>
      </div>
   )
}
