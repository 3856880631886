import { useTransactionsActionContext } from '../../../../../utilis/contexts/TransactionsActionContext'
import Purchase from './Purchase'
import Selling from './Selling'
import Swap from './Swap'

export default function Actions() {
   const { activeAction } = useTransactionsActionContext()
   return (
      // <ErrorBoundary fallback={<div>Une erreur est survenue</div>}>
      <>
         {activeAction === 'Achats' && <Purchase />}
         {activeAction === 'Ventes' && <Selling />}
         {activeAction === 'Echanges' && <Swap />}
         {/* {activeAction === 'Marché' && <Market />} */}
      </>
      // </ErrorBoundary>
   )
}
