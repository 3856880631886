import { Button } from 'flowbite-react'
import { FaEquals } from 'react-icons/fa6'
import ContentLoader from '../../../../../components/ContentLoader'
import { useActionContext } from '../../../../../utilis/contexts/ActionContext'
import { useApiContext } from '../../../../../utilis/contexts/ApiContext'
import { usePurchaseContext } from '../../../../../utilis/contexts/PurchaseContext'
import {
   InputAmout,
   InputWallet,
   SelectCurrency,
   SelectPaymentMethod,
   ShowAmount,
} from '../_componenets'
import PurchaseModal from './PurchaseModal'
export default function Purchase() {
   const { isLoadingCurrencies, payment_methods } = useApiContext()
   const { purchase, addWallet, clear, addMethod, openModal } =
      usePurchaseContext()

   return isLoadingCurrencies && !purchase ? (
      <ContentLoader />
   ) : (
      <div className="flex-1 rounded-lg dark:border-secondary md:bg-darktext md:p-4 dark:md:bg-dark-400">
         {/* Choisir une */}
         <div className="flex w-full flex-col items-center gap-4">
            <Currency />
            {purchase?.amount && (
               <>
                  <div className="flex w-full flex-row justify-between gap-4">
                     <ShowAmount
                        name="Frais"
                        amount={{
                           value: purchase.fees,

                           currency: '$',
                        }}
                     />
                     <ShowAmount
                        name="Total"
                        amount={{
                           value: purchase.total,
                           currency: '$',
                        }}
                     />
                  </div>
                  <InputWallet sendValue={addWallet} value={purchase?.wallet} />
                  {purchase.wallet && (
                     <>
                        <SelectPaymentMethod sendValue={addMethod} />
                        {payment_methods && (
                           <div className="flex w-full flex-row gap-4">
                              <Button
                                 onClick={() => openModal()}
                                 className="w-full rounded-2xl bg-primary text-6 font-semibold text-white"
                              >
                                 Acheter
                              </Button>
                              <Button
                                 onClick={() => clear()}
                                 color="gray"
                                 className=" w-full rounded-2xl text-6 font-semibold"
                              >
                                 Annuler
                              </Button>
                           </div>
                        )}
                     </>
                  )}
               </>
            )}
         </div>
         <PurchaseModal />
      </div>
   )
}

function Currency() {
   const { activeAction } = useActionContext()
   const { purchase, addAmount, addCurrency } = usePurchaseContext()

   return (
      <div className="flex w-full flex-col items-center justify-between rounded-lg bg-darktext-white  p-4 dark:bg-secondary md:bg-darktext-white">
         <div className="flex w-full items-center justify-start px-2 ">
            <span className=" md:text-5 md-max:text-secondary dark:md-max:text-darktext-white">
               Montant à {activeAction}
            </span>
         </div>
         <div className="flex w-full flex-row items-center justify-between gap-2 px-2">
            <InputAmout sendValue={addAmount} amount={purchase?.amount} />
            <FaEquals className="text-7" />
            <SelectCurrency
               sendValue={addCurrency}
               amount={purchase?.amount}
               value={purchase?.currency}
            />
         </div>
      </div>
   )
}
