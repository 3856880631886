import { useActionContext } from '../../../../utilis/contexts/ActionContext'
import Market from '../Market'
import Purchase from './Purchase'
import Selling from './Selling'
import Swap from './Swap'

export default function Actions() {
   const { activeAction } = useActionContext()
   return (
      // <ErrorBoundary fallback={<div>Une erreur est survenue</div>}>
      <>
         {activeAction === 'Acheter' && <Purchase />}
         {activeAction === 'Vendre' && <Selling />}
         {activeAction === 'Echanger' && <Swap />}
         {activeAction === 'Marché' && <Market />}
      </>
      // </ErrorBoundary>
   )
}
