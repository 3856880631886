import { Modal, ModalBody } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { CgArrowsExchangeAlt } from 'react-icons/cg'
import { FaX } from 'react-icons/fa6'
import CurrencyLogo from '../../../../../../components/CurrencyLogo/CurrencyLogo'
import { useSwapContext } from '../../../../../../utilis/contexts/SwapContext'
import { TransactionStateTag } from '../Purchase'
import { InputWallet } from '../_componenets'

export default function SwapModal() {
   const { closeShowModal, showSwapModalState, show } = useSwapContext()
   // const [check, setCheck] = useState(false)
   const [total, setTotal] = useState()
   const [equivalent, setEquivalent] = useState()

   useEffect(() => {
      const eq = show
         ? parseFloat(show?.amount) * parseFloat(show?.price)
         : null
      eq && setEquivalent(parseFloat(show?.amount) * parseFloat(show?.price))
      eq && setTotal(eq - parseFloat(show?.rate))
   }, [show])

   return (
      show?.amount && (
         <Modal
            size="3xl"
            show={showSwapModalState}
            onClose={closeShowModal}
            popup
            dismissible={true}
            className="h-screen p-0"
         >
            <ModalBody className="rounded-lg p-4 dark:bg-secondary md:p-5">
               <div className="flex h-full w-full flex-col justify-between gap-4">
                  <div className="flex flex-row items-center justify-between">
                     <span className="p-4"></span>
                     <span className="text-5 font-semibold">Echange</span>
                     <FaX
                        onClick={() => closeShowModal()}
                        className="cursor-pointer rounded-full bg-darktext-white p-2 text-8 font-bold text-secondary hover:bg-red-700 dark:bg-dark dark:text-darktext-white"
                     />
                  </div>
                  <div className="flex w-full flex-row items-center justify-center gap-2  rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex  flex-row items-center justify-center gap-2 text-5 font-bold md:text-7 ">
                        <span className="text-overflow ">
                           {/* {parseFloat(show?.amount)} */}
                        </span>
                        <CurrencyLogo img={show?.from?.cover} />
                     </div>
                     <CgArrowsExchangeAlt className="" />
                     <div className="flex  flex-row items-center justify-center gap-2 text-5 font-bold md:text-7 ">
                        <span className="text-overflow ">
                           {/* {parseFloat(show?.total)} */}
                        </span>
                        <CurrencyLogo img={show?.to?.cover} />
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Montant Echangé :</span>
                        <div className="flex flex-row items-center gap-1">
                           <span>{show?.amount}</span>
                           <CurrencyLogo img={show?.from?.cover} />
                        </div>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Equivalant :</span>
                        <div className="flexrow flex  items-center gap-1">
                           <span>{equivalent} </span>
                           <CurrencyLogo img={show?.to?.cover} />
                        </div>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Frais :</span>
                        <div className="flexrow flex  items-center gap-1">
                           <span>{show?.rate}</span>
                           <CurrencyLogo img={show?.to?.cover} />
                        </div>
                     </div>
                     <div className=" flex w-full flex-row items-center justify-between border-t pt-2 ">
                        <span>Montant Total à Recevoir :</span>
                        <div className="flexrow flex  items-center gap-1">
                           <span>{total}</span>
                           <CurrencyLogo img={show?.to?.cover} />
                        </div>
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Votre Portefeuille {show?.to?.name} :</span>
                        {/* <span>{show?.currency?.name}</span> */}
                     </div>
                     <div className="flex w-full flex-row   items-center justify-between ">
                        {/* <span className="">Portefeuille :</span> */}
                        {/* <span className="">{show?.currency?.wallet}</span> */}
                        <InputWallet
                           // sendValue={addWallet}
                           copy={true}
                           value={show?.swap_wallet}
                           placeholder={
                              'Ajouter le Numero ' + show?.method?.name
                           }
                        />
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Reference :</span>
                        <div className="flex flex-row items-center gap-1">
                           <span>{show?.reference}</span>
                           {/* <CurrencyLogo img={show?.from?.cover} /> */}
                        </div>
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Statut:</span>
                        <TransactionStateTag state={show?.state} />
                     </div>
                  </div>
               </div>
            </ModalBody>
         </Modal>
      )
   )
}
