import { Button, Modal, ModalBody } from 'flowbite-react'
import { useEffect } from 'react'
import { FaX } from 'react-icons/fa6'
import { IoWarning } from 'react-icons/io5'
import CurrencyLogo from '../../../../../components/CurrencyLogo/CurrencyLogo'
import { usePopUpContext } from '../../../../../utilis/contexts/PopUpContext'
import { useSellingContext } from '../../../../../utilis/contexts/SellingContext'
import { useSessionContext } from '../../../../../utilis/contexts/SessionContext'
import { usePost } from '../../../../../utilis/hooks'
import { InputWallet } from '../_componenets'

export default function PurchaseModal() {
   const { loggedUser, destroySession } = useSessionContext()
   const { openPopUp } = usePopUpContext()
   const { closeModal, sellingModalState, selling, clear } = useSellingContext()
   const [response, error, isLoading, setPost] = usePost('/api/sellings/store')
   // const [check, setCheck] = useState(false)

   useEffect(() => {
      if (response) {
         if (response === 'success') {
            openPopUp({
               title: 'Vente ✅',
               content:
                  'Vente effectué avec succès, vous pouvez retrouver les details de celui-ci dans le menu Transactions ',
               default: {
                  title: 'Ok',
                  handler: () => {
                     clear()
                     closeModal()
                  },
               },
            })
         } else {
            openPopUp({
               title: 'Vente ⚠️',
               content:
                  'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
               default: {
                  title: 'Reessayer',
                  // handler: clear,
               },
            })
         }
      } else {
         if (error) {
            if (error?.code === 403) {
               openPopUp({
                  title: 'Session Expirée ⚠️',
                  content:
                     "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
                  default: {
                     title: 'Ok',
                     handler: destroySession,
                  },
               })
            } else {
               openPopUp({
                  title: 'Vente ⚠️',
                  content:
                     'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
                  default: {
                     title: 'Reessayer',
                     // handler: clear,
                  },
               })
            }
         }
      }
   }, [response, error])

   const handlePost = () => {
      setPost({
         user_id: loggedUser?.id,
         currency_id: selling?.currency?.id,
         payment_method_id: selling?.method?.id,
         amount: selling?.amount,
         rate: selling?.fees,
         price: selling?.price,
         wallet: selling?.wallet,
         // reference: ref,
      })
   }
   return (
      selling?.amount && (
         <Modal
            size="3xl"
            show={sellingModalState}
            onClose={closeModal}
            popup
            dismissible={true}
            className="h-screen p-0"
         >
            <ModalBody className="rounded-lg p-4 dark:bg-secondary md:p-5">
               <div className="flex h-full w-full flex-col justify-between gap-4">
                  <div className="flex flex-row items-center justify-between">
                     <span className="p-4"></span>
                     <span className="text-5 font-semibold">Vente de</span>
                     <FaX
                        onClick={() => closeModal()}
                        className="cursor-pointer rounded-full bg-darktext-white p-2 text-8 font-bold text-secondary hover:bg-red-700 dark:bg-dark dark:text-darktext-white"
                     />
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-center gap-2 text-5 font-bold md:text-7 ">
                        <span className="text-overflow ">
                           {Number(selling.amount) /
                              Number(selling.currency.price)}
                        </span>
                        <CurrencyLogo img={selling?.currency?.cover} />
                        {/* <selling.currency.avatar /> */}
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Montant en USD :</span>
                        <span>{selling?.amount} $</span>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Frais :</span>
                        <span>{selling?.fees} $</span>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Montant Total à Recevoir :</span>
                        <span>{selling?.total} $</span>
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>
                           Notre Portefeuille {selling?.currency?.name} :
                        </span>
                        {/* <span>{selling?.currency?.name}</span> */}
                     </div>
                     <div className="flex w-full flex-row   items-center justify-between ">
                        {/* <span className="">Portefeuille :</span> */}
                        {/* <span className="">{selling?.currency?.wallet}</span> */}
                        <InputWallet
                           // sendValue={addWallet}
                           copy={true}
                           value={selling?.currency?.wallet}
                           placeholder={
                              'Ajouter le Numero ' + selling?.method?.name
                           }
                        />
                     </div>
                  </div>

                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl  border-2 border-pink-700 bg-darktext-white bg-opacity-30 p-4 font-semibold dark:border-2 dark:border-red-900 dark:bg-dark-400  md:text-5">
                     <div className="flex w-full flex-col items-center justify-between ">
                        {/* <FaWarn */}
                        <div className="flex flex-row items-center text-pink-700 dark:text-red-700">
                           <IoWarning className="text-7" />
                           <span>NOTICE IMPORTANTE</span>
                        </div>
                        <span className="text-pink-00 text-center text-4 font-light">
                           Assurez-vous de bien envoyer{' '}
                           <span className="font-bold">
                              {Number(selling.amount) /
                                 Number(selling.currency.price)}
                              {selling?.currency?.name}
                           </span>{' '}
                           sur l'adresse de notre Portefeuille ci-haut{' '}
                           {/* <span className="font-bold">
                              {selling?.method?.name}
                           </span>{' '}
                           suivant :{' '}
                           <span className="font-bold">
                              {selling?.method?.wallet}
                           </span>
                           , puis saisiez le code de reference de la transaction */}
                           avant de{' '}
                           <span className="font-bold">Confirmer la Vente</span>
                           . Une fois la transaction faite, vous receverez{' '}
                           <span className="font-bold">{selling?.total}</span> $
                           sur votre numero {selling?.method?.name} :{' '}
                           <span className="font-bold">{selling?.wallet}</span>
                        </span>
                        {/* <span>Airtel Momo</span> */}
                     </div>
                  </div>
                  {/* <TextInput
                     type="text"
                     placeholder="Reference de la transaction"
                     className="text-7 dark:bg-secondary-500"
                  /> */}
                  <div className="flex w-full flex-col gap-4 sm:flex-row">
                     <Button
                        isProcessing={isLoading}
                        onClick={() => handlePost()}
                        className="w-full rounded-xl bg-primary text-6 font-semibold text-white"
                     >
                        Confirmer la Vente
                     </Button>
                     <Button
                        onClick={() => {
                           clear()
                           closeModal()
                        }}
                        color="gray"
                        className=" w-full rounded-2xl text-6 font-semibold"
                     >
                        Annuler
                     </Button>
                  </div>
               </div>
            </ModalBody>
         </Modal>
      )
   )
}
