import { Avatar, Table, Tooltip } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { FaCopy, FaShareAlt } from 'react-icons/fa'
import { HiUserAdd } from 'react-icons/hi'
import {
   FacebookIcon,
   FacebookShareButton,
   TelegramIcon,
   TelegramShareButton,
   TwitterShareButton,
   WhatsappIcon,
   WhatsappShareButton,
   XIcon,
} from 'react-share'
import CustomPopUp from '../../../components/CustomPopUp/CustomPopUp'
import { useApiContext } from '../../../utilis/contexts/ApiContext'
import { useNavContext } from '../../../utilis/contexts/NavContext'
import { copyTextToClipboard } from '../../../utilis/functions'
import { useScreenWidth } from '../../../utilis/hooks/display'
import { Wallet } from '../profile'
import { InputWallet, TheToast } from '../_components/Actions/_componenets'

export default function Parrainange() {
   const { handler } = useNavContext()
   const { refresh } = useApiContext()
   useEffect(() => {
      handler('Parrainages')
      refresh()
   }, [])
   return (
      <div className="flex h-full w-full flex-col gap-4 pb-2 md:flex-row md:justify-center">
         <Parrainanges />
         <div className=" flex w-full flex-col gap-4 md:h-full lg:w-5/12">
            <diw className="flex w-full flex-col rounded border  p-4 dark:border-secondary">
               <span className="font-semibold">
                  Parrainé un nouveau utilisateur et gagnez 0.05USDT
               </span>
               <span className=" underline">
                  Comment faire ? c'est simple :{' '}
               </span>
               <span className="text-justify">
                  Commencez votre voyage crypto avec Mude Capital et gagnez des
                  récompenses grâce au parrainage Copiez et partagez votre lien
                  d'affiliation avec vos amis pour leur creation de compte et
                  optenez la chance de gagner une 0.05USDT.
               </span>
            </diw>
         </div>
      </div>
   )
}

function Parrainanges() {
   const { user } = useApiContext()
   return (
      <div className="flex w-full flex-col gap-4 lg:w-7/12">
         <Wallet />
         <div className="flex flex-col items-center gap-2 py-2">
            <span className="w-full text-left font-semibold">
               Votre lien d'affiliation :
            </span>
            <div className="flex w-full items-center gap-2">
               <Share
                  text={
                     "👋🏾 ✨ Tu cherches un moyen simple d'acheter et de vendre des cryptos ? Mude Capital est fait pour toi ! \n Utilise mon lien de parrainage pour commencer. 💰💸 : "
                  }
                  link={
                     'https://app.mudecapital.com/register?promo_code=' +
                     user?.promo_code
                  }
                  title="Votre lien:"
               />
               <InputWallet
                  readOnly={true}
                  Icon={HiUserAdd}
                  value={
                     'https://app.mudecapital.com/register?promo_code=' +
                     user?.promo_code
                  }
                  // copy={true}
               />
            </div>
         </div>
         {user && user?.childs && (
            <div className="overflow-x-auto">
               <Table>
                  <Table.Head className="bg-darktext">
                     <Table.HeadCell>Comptes Affiliés</Table.HeadCell>
                     <Table.HeadCell>Statut</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y ">
                     {user && user?.childs ? (
                        user?.childs?.map((child) => (
                           <Table.Row className="bg-darktext-white dark:border-gray-700 dark:bg-secondary">
                              <Table.Cell className="flex items-center gap-2 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                 <Avatar rounded />
                                 {child.names}
                              </Table.Cell>
                              <Table.Cell>
                                 {child?.is_active ? (
                                    <span className="rounded bg-green-700 px-2 py-1 text-darktext-white">
                                       Actif
                                    </span>
                                 ) : (
                                    <span className="rounded bg-red-700 px-2 py-1 text-darktext-white">
                                       Inactif
                                    </span>
                                 )}
                              </Table.Cell>
                           </Table.Row>
                        ))
                     ) : (
                        <Table.Row className="bg-darktext-white dark:border-gray-700 dark:bg-secondary">
                           <Table.Cell colSpan={2}>
                              <span className="rounded bg-red-700 px-2 py-1 text-darktext-white">
                                 Inactif
                              </span>
                           </Table.Cell>
                        </Table.Row>
                     )}
                  </Table.Body>
               </Table>
            </div>
         )}
      </div>
   )
}

function Share({ text, link, title }) {
   // const [show, setShow] = useState(false)
   const [share, setShare] = useState(false)
   const [isBig, isSmall] = useScreenWidth()
   const handleShare = () => {
      console.log(isBig)
      setShare(!share)
   }
   const [toast, setToast] = useState(false)
   // const [error, setError] = useState(false)
   const handleTost = (message) => {
      setToast(message)

      setTimeout(() => setToast(), 2000)
   }
   return (
      <>
         <div
            onClick={handleShare}
            className="cursor-pointer items-center justify-center rounded-lg bg-darktext-white p-4 dark:bg-gray-700"
         >
            <FaShareAlt className=" text-5 text-gray-600 dark:text-slate-200" />
         </div>
         <CustomPopUp title={title} close={handleShare} state={share}>
            <div className="flex flex-col items-center gap-2 p-4">
               <span className="font-semibold text-gray-600 dark:text-slate-200">
                  Partager sur :
               </span>
               <div className="flex flex-row items-center gap-2">
                  <WhatsappShareButton
                     url={link}
                     title={text}
                     className="Demo__some-network__share-button"
                  >
                     <WhatsappIcon size={isSmall ? 32 : 48} round />
                  </WhatsappShareButton>
                  <TelegramShareButton
                     url={link}
                     title={text}
                     className="Demo__some-network__share-button"
                  >
                     <TelegramIcon size={isSmall ? 32 : 48} round />
                  </TelegramShareButton>
                  <FacebookShareButton
                     title={text}
                     url={link}
                     className="Demo__some-network__share-button"
                  >
                     <FacebookIcon size={isSmall ? 32 : 48} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                     url={link}
                     title={text}
                     className="Demo__some-network__share-button"
                  >
                     <XIcon size={isSmall ? 32 : 48} round />
                  </TwitterShareButton>

                  <Tooltip content="Copier" color="auto" placement="top">
                     <FaCopy
                        className="cursor-pointer text-6"
                        onClick={() => {
                           copyTextToClipboard(text + '\n ' + link).then(
                              (val) => {
                                 val && handleTost('Le lien a été copiée')
                              },
                           )
                        }}
                     />
                  </Tooltip>

                  {toast && <TheToast toast={toast} />}
               </div>
            </div>
         </CustomPopUp>
      </>
   )
}
