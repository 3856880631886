import { CgArrowsExchangeAlt } from 'react-icons/cg'
import { FaUser } from 'react-icons/fa6'
import { HiHome, HiUserAdd } from 'react-icons/hi'
import { RiHomeFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { useNavContext } from '../../utilis/contexts/NavContext'
export default function MobileNavBar() {
   return (
      <div className="fixed bottom-0 left-0 z-10 flex w-screen bg-bg px-4 pb-6  dark:bg-dark md:hidden md:h-full">
         <div className=" bg-darktext- dark:bg- flex w-full justify-around gap-4 rounded-2xl text-7 dark:border-secondary">
            <Menu name="Tableau de Bord" Icon={RiHomeFill} to={'/'} />
            <Menu
               name="Transactions"
               Icon={CgArrowsExchangeAlt}
               // active={true}
               to={'/transactions'}
            />
            <Menu to={'/parrainages'} name="Parrainages" Icon={HiUserAdd} />
            <Menu to={'/account'} name="Mon Compte" Icon={FaUser} />
            {/* <Menu name="Compte" Icon={FaUser} /> */}
            {/* <CgArrowsExchangeAlt />
            <FaUser /> */}
            {/* <IoSettings /> */}
            {/* <HiHome /> */}
         </div>
      </div>
   )
}

function Menu({ name, to, Icon }) {
   const { activeNav } = useNavContext()
   return (
      <Link
         to={to}
         // onClick={() => handler(name)}
         className={
            activeNav === name
               ? 'flex w-full cursor-pointer flex-row items-center justify-center gap-2 rounded-2xl bg-darktext-white p-4 text-primary  dark:bg-secondary   dark:text-darktext-white dark:hover:text-bg'
               : 'flex w-full cursor-pointer flex-row items-center justify-center gap-2 rounded-2xl p-4 text-secondary hover:bg-darktext-white  dark:text-darktext   dark:hover:bg-secondary dark:hover:text-bg'
         }
      >
         {Icon ? <Icon className="text-6" /> : <HiHome className="text-6 " />}
         {activeNav === 20 && (
            <span className=" text-4 font-semibold">
               {name ? name : 'Menu bar'}
            </span>
         )}
      </Link>
   )
}
