import CurrencyLogo from '../../../../../../components/CurrencyLogo/CurrencyLogo'
import { useApiContext } from '../../../../../../utilis/contexts/ApiContext'
import { usePurchaseContext } from '../../../../../../utilis/contexts/PurchaseContext'
import PurchaseModal from './PurchaseModal'

export default function Purchase() {
   const { user } = useApiContext()
   return (
      <div className="h-200 flex-1 overflow-y-auto rounded-lg dark:bg-dark-400 md:bg-darktext md:p-4 dark:md:border-secondary">
         {/* Choisir une */}
         <div className="flex w-full flex-col items-center gap-4">
            {user?.purchases?.length > 0 ? (
               user.purchases.map((purchase) => (
                  <Currency purchase={purchase} key={purchase?.id + 'okm'} />
               ))
            ) : (
               <div className="h-full p-4">
                  <span className="text-dark dark:text-darktext-white">
                     Vous n'avez effectuez aucun Achat pour le moment.
                  </span>
               </div>
            )}
         </div>
         <PurchaseModal />
      </div>
   )
}

function Currency({ purchase }) {
   const { openShowModal } = usePurchaseContext()
   return (
      <div
         onClick={() => openShowModal(purchase)}
         className="justify- flex w-full cursor-pointer flex-row items-center gap-2 rounded-lg bg-darktext-white px-4 py-2  dark:bg-secondary md:bg-darktext-white md:p-4"
      >
         <div className="flex items-center justify-center rounded-lg p-2 dark:bg-dark-400">
            {purchase?.currency?.cover && (
               <CurrencyLogo img={purchase?.currency?.cover} />
            )}
         </div>
         <div className="flex w-full flex-row items-center justify-between">
            <div className="flex flex-col gap-2">
               {purchase?.currency && (
                  <span className="font-bold md:text-6">
                     {purchase?.currency.name}
                  </span>
               )}
               {/* {purchase?.amount && (
                  <span className="text- font-bold">{purchase?.amount} $</span>
               )} */}
            </div>
            <div className="gap- flex flex-col items-end">
               {purchase?.amount && (
                  <span className="font-bold md:text-6">
                     $ {purchase?.amount}
                  </span>
               )}
               <TransactionStateTag state={purchase?.state} />
            </div>
         </div>
      </div>
   )
}

export function TransactionStateTag({ state }) {
   return state && state === 'en attente' ? (
      <span className="rounded-lg bg-gray-400 px-2 font-bold text-bg dark:bg-dark-400 dark:text-darktext">
         {state}
      </span>
   ) : state === 'Approuvé' ? (
      <span className="rounded-lg bg-green-600 px-2 font-bold text-bg ">
         {state}
      </span>
   ) : (
      <span className="rounded-lg bg-red-700 px-2 font-bold text-bg ">
         {state}
      </span>
   )
}
