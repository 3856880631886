import {
   assetsLink,
   useApiContext,
} from '../../../../../../utilis/contexts/ApiContext'
import { useSellingContext } from '../../../../../../utilis/contexts/SellingContext'
import { TransactionStateTag } from '../Purchase'
import SellingModal from './SellingModal'

export default function Selling() {
   const { user } = useApiContext()
   return (
      <div className="h-200 flex-1 overflow-y-auto rounded-lg dark:bg-dark-400 md:bg-darktext md:p-4 dark:md:border-secondary">
         <div className="flex w-full flex-col items-center gap-4">
            {user?.sellings?.length > 0 ? (
               user.sellings.map((selling) => (
                  <Currency purchase={selling} key={selling?.id + 'okm'} />
               ))
            ) : (
               <div className=" w-full p-4 text-center text-dark dark:text-darktext-white">
                  <span>
                     Vous n'avez effectuez aucune Vente pour le moment.
                  </span>
               </div>
            )}
         </div>
         <SellingModal />
      </div>
   )
}

function Currency({ purchase }) {
   const { openShowModal } = useSellingContext()
   return (
      <div
         onClick={() => openShowModal(purchase)}
         className="justify- flex w-full cursor-pointer flex-row items-center gap-2 rounded-lg bg-darktext-white px-4 py-2  dark:bg-secondary md:bg-darktext-white md:p-4"
      >
         <div className="flex items-center justify-center rounded-lg p-2 dark:bg-dark-400">
            {purchase?.currency?.cover && (
               <Logo img={purchase?.currency?.cover} />
            )}
         </div>
         <div className="flex w-full flex-row items-center justify-between">
            <div className="flex flex-col gap-2">
               {purchase?.currency && (
                  <span className="font-bold md:text-6">
                     {purchase?.currency.name}
                  </span>
               )}
               {/* {purchase?.amount && (
                  <span className="text- font-bold">{purchase?.amount} $</span>
               )} */}
            </div>
            <div className="gap- flex flex-col items-end">
               {purchase?.amount && (
                  <span className="font-bold md:text-6">
                     $ {purchase?.amount}
                  </span>
               )}
               <TransactionStateTag state={purchase?.state} />
            </div>
         </div>
      </div>
   )
}

function Logo({ img }) {
   return (
      <div>
         <img src={assetsLink + img} alt="logo" srcset="" />
      </div>
   )
}
