import { Button, Modal, ModalBody } from 'flowbite-react'
import { useEffect } from 'react'
import { CgArrowsExchangeAlt } from 'react-icons/cg'
import { FaX } from 'react-icons/fa6'
import { IoWarning } from 'react-icons/io5'
import CurrencyLogo from '../../../../../components/CurrencyLogo/CurrencyLogo'
import { usePopUpContext } from '../../../../../utilis/contexts/PopUpContext'
import { useSessionContext } from '../../../../../utilis/contexts/SessionContext'
import { useSwapContext } from '../../../../../utilis/contexts/SwapContext'
import { usePost } from '../../../../../utilis/hooks'
import { InputWallet } from '../_componenets'

export default function SwapModal() {
   const { loggedUser, destroySession } = useSessionContext()
   const { openPopUp } = usePopUpContext()
   const [response, error, isLoading, setPost] = usePost('/api/swaps/store')
   const { closeModal, swapModalState, swap, clear } = useSwapContext()
   // const [check, setCheck] = useState(false)

   useEffect(() => {
      if (response) {
         if (response === 'success') {
            openPopUp({
               title: 'Echange ✅',
               content:
                  'Echange effectué avec succès, vous pouvez retrouver les details de celui-ci dans le menu Transactions ',
               default: {
                  title: 'Ok',
                  handler: () => {
                     clear()
                     closeModal()
                  },
               },
            })
         } else {
            openPopUp({
               title: 'Echange ⚠️',
               content:
                  'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
               default: {
                  title: 'Reessayer',
                  // handler: clear,
               },
            })
         }
      } else {
         if (error) {
            if (error?.code === 403) {
               openPopUp({
                  title: 'Session Expirée ⚠️',
                  content:
                     "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
                  default: {
                     title: 'Ok',
                     handler: destroySession,
                  },
               })
            } else {
               openPopUp({
                  title: 'Echange ⚠️',
                  content:
                     'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
                  default: {
                     title: 'Reessayer',
                     // handler: clear,
                  },
               })
            }
         }
      }
   }, [response, error])

   const handlePost = () => {
      setPost({
         user_id: loggedUser?.id,
         from_currency_id: swap?.from?.id,
         to_currency_id: swap?.to?.id,
         payment_method_id: swap?.method?.id,
         amount: swap?.amount,
         rate: swap?.fees,
         price: swap?.price,
         wallet: swap?.wallet,
         // reference: ref,
      })
   }

   return (
      swap?.amount && (
         <Modal
            size="3xl"
            show={swapModalState}
            onClose={closeModal}
            popup
            dismissible={true}
            className="h-screen p-0"
         >
            <ModalBody className="rounded-lg p-4 dark:bg-secondary md:p-5">
               <div className="flex h-full w-full flex-col justify-between gap-4">
                  <div className="flex flex-row items-center justify-between">
                     <span className="p-4"></span>
                     <span className="text-5 font-semibold">Echange</span>
                     <FaX
                        onClick={() => closeModal()}
                        className="cursor-pointer rounded-full bg-darktext-white p-2 text-8 font-bold text-secondary hover:bg-red-700 dark:bg-dark dark:text-darktext-white"
                     />
                  </div>
                  <div className="flex w-full flex-row items-center justify-center gap-2  rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex  flex-row items-center justify-center gap-2 text-5 font-bold md:text-7 ">
                        <span className="text-overflow ">
                           {/* {parseFloat(swap?.amount)} */}
                        </span>
                        <CurrencyLogo img={swap?.from?.cover} />
                     </div>
                     <CgArrowsExchangeAlt className="" />
                     <div className="flex  flex-row items-center justify-center gap-2 text-5 font-bold md:text-7 ">
                        <span className="text-overflow ">
                           {/* {parseFloat(swap?.total)} */}
                        </span>
                        <CurrencyLogo img={swap?.to?.cover} />
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Montant à Echanger :</span>
                        <div className="flex flex-row items-center gap-1">
                           <span>{swap?.amount}</span>
                           <CurrencyLogo img={swap?.from?.cover} />
                        </div>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Equivalant :</span>
                        <div className="flexrow flex  items-center gap-1">
                           <span>
                              {parseFloat(swap?.amount) *
                                 parseFloat(swap?.price)}
                           </span>
                           <CurrencyLogo img={swap?.to?.cover} />
                        </div>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Frais :</span>
                        <div className="flexrow flex  items-center gap-1">
                           <span>{swap?.fees}</span>
                           <CurrencyLogo img={swap?.to?.cover} />
                        </div>
                     </div>
                     <div className=" flex w-full flex-row items-center justify-between border-t pt-2 ">
                        <span>Montant Total à Recevoir :</span>
                        <div className="flexrow flex  items-center gap-1">
                           <span>
                              {parseFloat(swap?.amount) *
                                 parseFloat(swap?.price) -
                                 parseFloat(swap?.fees)}
                           </span>
                           <CurrencyLogo img={swap?.to?.cover} />
                        </div>
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Notre Portefeuille {swap?.currency?.name} :</span>
                        {/* <span>{swap?.currency?.name}</span> */}
                     </div>
                     <div className="flex w-full flex-row   items-center justify-between ">
                        {/* <span className="">Portefeuille :</span> */}
                        {/* <span className="">{swap?.currency?.wallet}</span> */}
                        <InputWallet
                           // sendValue={addWallet}
                           copy={true}
                           value={swap?.from?.wallet}
                           placeholder={
                              'Ajouter le Numero ' + swap?.method?.name
                           }
                        />
                     </div>
                  </div>

                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl  border-2 border-pink-700 bg-darktext-white bg-opacity-30 p-4 font-semibold dark:border-2 dark:border-red-900 dark:bg-dark-400  md:text-5">
                     <div className="flex w-full flex-col items-center justify-between ">
                        {/* <FaWarn */}
                        <div className="flex flex-row items-center text-pink-700 dark:text-red-700">
                           <IoWarning className="text-7" />
                           <span>NOTICE IMPORTANTE</span>
                        </div>
                        <span className="text-pink-00 text-center text-4 font-light">
                           Assurez-vous de bien envoyer{' '}
                           <span className="font-bold">
                              {swap?.amount} {swap?.from?.short_name}
                           </span>{' '}
                           sur l'adresse de notre Portefeuille ci-haut{' '}
                           {/* <span className="font-bold">
                              {swap?.method?.name}
                           </span>{' '}
                           suivant :{' '}
                           <span className="font-bold">
                              {swap?.method?.wallet}
                           </span>
                           , puis saisiez le code de reference de la transaction */}
                           avant de Confirmer. Une fois la transaction faite,
                           vous receverez{' '}
                           <span className="font-bold">
                              {parseFloat(swap?.amount) *
                                 parseFloat(swap?.price) -
                                 parseFloat(swap?.fees)}{' '}
                              {swap?.to?.short_name}
                           </span>{' '}
                           sur votre portefeuille.
                        </span>
                        {/* <span>Airtel Momo</span> */}
                     </div>
                  </div>
                  {/* <TextInput
                     type="text"
                     placeholder="Reference de la transaction"
                     className="text-7 dark:bg-secondary-500"
                  /> */}
                  <div className="flex w-full flex-col gap-4 sm:flex-row">
                     <Button
                        onClick={() => handlePost()}
                        isProcessing={isLoading}
                        className="w-full rounded-xl bg-primary text-6 font-semibold text-white"
                     >
                        Confirmer l'Echange
                     </Button>
                     <Button
                        onClick={() => {
                           clear()
                           closeModal()
                        }}
                        color="gray"
                        className=" w-full rounded-2xl text-6 font-semibold"
                     >
                        Annuler
                     </Button>
                  </div>
               </div>
            </ModalBody>
         </Modal>
      )
   )
}
