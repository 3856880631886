import {
   CgArrowBottomLeftR,
   CgArrowsExchangeAltV,
   CgArrowTopRightR,
} from 'react-icons/cg'

import { Tooltip } from 'flowbite-react'
import { HiCash } from 'react-icons/hi'
import { useTransactionsActionContext } from '../../../../utilis/contexts/TransactionsActionContext'
export default function ActionBar() {
   return (
      <div className="flex flex-wrap items-center justify-start gap-2 md:gap-4">
         <ActionButton name={'Achats'} Icon={CgArrowBottomLeftR} />
         <ActionButton name={'Ventes'} Icon={CgArrowTopRightR} />
         <ActionButton name={'Echanges'} Icon={CgArrowsExchangeAltV} />
      </div>
   )
}

export function ActionButton({ name, Icon, className, disabled }) {
   const { openAction, activeAction } = useTransactionsActionContext()
   return (
      <Tooltip
         content={'Mes ' + name}
         hidden={activeAction === name}
         className={activeAction === name ? 'hidden' : 'md:hidden'}
         color="auto"
         placement="bottom"
      >
         <div
            onClick={() => !disabled && openAction(name)}
            className={
               className +
               ' ' +
               (activeAction === name
                  ? 'flex cursor-pointer flex-row items-center justify-center gap-2 rounded-full bg-darktext-white bg-secondary px-4 py-2  text-5 font-semibold text-bg dark:bg-darktext dark:text-secondary lg:text-5'
                  : 'flex cursor-pointer flex-row items-center justify-center gap-2 rounded-full bg-darktext-white p-2 text-5 font-semibold hover:bg-tertiery  hover:text-darktext-white dark:bg-secondary dark:text-darktext dark:hover:bg-darktext dark:hover:text-secondary sm:px-4 sm:py-2 lg:text-5')
            }
         >
            {Icon ? <Icon className="text-6" /> : <HiCash className="text-6" />}
            <span className={activeAction === name ? '' : 'sm-max:hidden'}>
               {name}
            </span>
         </div>
      </Tooltip>
   )
}
