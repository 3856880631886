import { Button, Modal, ModalBody, TextInput } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { FaCopy } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6'
import { IoWarning } from 'react-icons/io5'
import CurrencyLogo, {
   MethodLogo,
} from '../../../../../components/CurrencyLogo/CurrencyLogo'
import { usePopUpContext } from '../../../../../utilis/contexts/PopUpContext'
import { usePurchaseContext } from '../../../../../utilis/contexts/PurchaseContext'
import { useSessionContext } from '../../../../../utilis/contexts/SessionContext'
import { copyTextToClipboard } from '../../../../../utilis/functions'
import { usePost } from '../../../../../utilis/hooks'
import { TheToast } from '../_componenets'

export default function PurchaseModal() {
   const { closeModal, purchaseModalState, purchase, clear } =
      usePurchaseContext()
   const { openPopUp } = usePopUpContext()
   const { loggedUser, destroySession } = useSessionContext()
   const [response, error, isLoading, setPost] = usePost('/api/purchases/store')
   const [ref, setRef] = useState()
   const [check, setCheck] = useState(false)

   useEffect(() => {
      if (response) {
         if (response === 'success') {
            openPopUp({
               title: 'Achat ✅',
               content:
                  'Achat effectué avec succès, vous pouvez retrouver les details de celui-ci dans le menu Transactions ',
               default: {
                  title: 'Ok',
                  handler: () => {
                     clear()
                     closeModal()
                  },
               },
            })
         } else {
            openPopUp({
               title: 'Achat ⚠️',
               content:
                  'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
               default: {
                  title: 'Reessayer',
                  // handler: clear,
               },
            })
         }
      } else {
         if (error) {
            console.log(error)
            if (error?.code === 403) {
               openPopUp({
                  title: 'Session Expirée ⚠️',
                  content:
                     "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
                  default: {
                     title: 'Ok',
                     handler: destroySession,
                  },
               })
            } else {
               openPopUp({
                  title: 'Achat ⚠️',
                  content:
                     'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
                  default: {
                     title: 'Reessayer',
                     // handler: clear,
                  },
               })
            }
         }
      }
   }, [response, error, isLoading])
   // 'user_id',
   // 'currency_id',
   // 'payment_method_id',
   // 'amount',
   // 'rate',
   // 'wallet',
   // 'reference',

   const handlePost = () => {
      setPost({
         user_id: loggedUser?.id,
         currency_id: purchase?.currency?.id,
         payment_method_id: purchase?.method?.id,
         amount: purchase?.amount,
         rate: purchase?.fees,
         price: purchase?.price,
         wallet: purchase?.wallet,
         reference: ref,
      })
      // console.log(purchase, loggedUser)
   }

   useEffect(() => {
      if (ref && ref.length > 8) {
         setCheck(true)
      } else {
         setCheck(false)
      }
   }, [ref])

   const [toast, setToast] = useState(false)
   // const [error, setError] = useState(false)
   const handleTost = (message) => {
      setToast(message)

      setTimeout(() => setToast(), 2000)
   }

   return (
      purchase?.amount && (
         <Modal
            size="3xl"
            show={purchaseModalState}
            onClose={closeModal}
            popup
            dismissible={true}
            // className="mb-4 pb-4"
         >
            <ModalBody className="rounded-lg p-2 pt-4 dark:bg-secondary sm:p-4 md:p-5">
               <div className="mb-24 flex max-h-[100dvh] w-full flex-col justify-between gap-4">
                  <div className="flex flex-row items-center justify-between">
                     <span className="p-4"></span>
                     <span className="text-5 font-semibold">Achat de</span>
                     <FaX
                        onClick={() => closeModal()}
                        className="cursor-pointer rounded-full bg-darktext-white p-2 text-8 font-bold text-secondary hover:bg-red-700 dark:bg-dark dark:text-darktext-white"
                     />
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-center gap-2 text-5 font-bold md:text-7 ">
                        <span className="text-overflow ">
                           {Number(purchase?.amount) / Number(purchase?.price)}
                        </span>
                        <CurrencyLogo img={purchase?.currency?.cover} />
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Prix en USD :</span>
                        <span>{purchase?.amount} $</span>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Frais :</span>
                        <span>{purchase?.fees} $</span>
                     </div>
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Total à payer :</span>
                        <span>{purchase?.total} $</span>
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                     <div className="flex w-full flex-row items-center justify-between ">
                        <span>Moyen de Paiement :</span>
                        <div className="flex items-center gap-1 ">
                           <MethodLogo img={purchase?.method?.cover} />
                           {purchase?.method?.name}
                        </div>
                     </div>
                     <div className="flex w-full flex-row   items-center justify-between ">
                        <span className="">Notre Portefeuille :</span>
                        <span className="flex flex-row items-center gap-1">
                           {purchase?.method?.wallet}{' '}
                           <FaCopy
                              className="cursor-pointer text-6"
                              onClick={() => {
                                 copyTextToClipboard(
                                    purchase?.method?.wallet,
                                 ).then((val) => {
                                    val && handleTost('Le numero a été copiée')
                                 })
                              }}
                           />
                        </span>
                        {toast && <TheToast toast={toast} />}
                     </div>
                  </div>

                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl  border-2 border-pink-700 bg-darktext-white bg-opacity-30 p-4 font-semibold dark:border-2 dark:border-red-900 dark:bg-dark-400  md:text-5">
                     <div className="flex w-full flex-col items-center justify-between ">
                        {/* <FaWarn */}
                        <div className="flex flex-row items-center text-pink-700 dark:text-red-700">
                           <IoWarning className="text-7" />
                           <span>NOTICE IMPORTANTE</span>
                        </div>
                        <span className="text-pink-00 text-center text-4 font-light">
                           Assurez-vous de bien envoyer{' '}
                           <span className="font-bold">{purchase?.total}$</span>{' '}
                           sur le numero{' '}
                           <span className="font-bold">
                              {purchase?.method?.name}
                           </span>{' '}
                           suivant :{' '}
                           <span className="font-bold">
                              {purchase?.method?.wallet}
                           </span>
                           , puis saisiez le code de reference de la transaction
                           avant de Confirmer. Une fois la transaction faite,
                           vous receverez{' '}
                           <span className="font-bold">
                              {purchase?.amount / purchase?.currency?.price}{' '}
                              {purchase?.currency?.short_name}
                           </span>{' '}
                           sur votre portefeuille.
                        </span>
                        {/* <span>Airtel Momo</span> */}
                     </div>
                  </div>
                  <TextInput
                     onChangeCapture={(e) => setRef(e.target.value)}
                     type="text"
                     placeholder="Reference de la transaction"
                     className="text-7 dark:bg-secondary-500"
                  />
                  <div className="flex w-full flex-col gap-4 sm:flex-row">
                     <Button
                        onClick={() => handlePost()}
                        disabled={!check}
                        isProcessing={isLoading}
                        className="w-full rounded-xl bg-primary text-6 font-semibold text-white"
                     >
                        Confirmer l'Achat
                     </Button>
                     <Button
                        onClick={() => {
                           clear()
                           closeModal()
                        }}
                        color="gray"
                        className=" w-full rounded-2xl text-6 font-semibold"
                     >
                        Annuler
                     </Button>
                  </div>
               </div>
            </ModalBody>
         </Modal>
      )
   )
}
